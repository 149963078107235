import { localKeepSignin, localUserToken, localUserRole } from '@libs/utils/utilLocalStorage'
import { IModelFakeWhoami } from 'containers/models/modelFakeWhoami'
import { IModelUser } from 'containers/models/modelUser'
import { MenuSiderAdmin, MenuSiderSeller } from 'domains/common/menus'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doSetFakeWhoamiData } from '@libs/redux/reduxData'
import { doSetUserRole } from '@libs/redux/reduxUI'
import { RootState, store } from '@libs/redux/store'
import { USER_MODE_TYPE, USER_MODE } from '@constants/constDomain'
import { ModelFakeWhoami } from 'containers/models'
import { IModelSellerDefault } from 'containers/models/base/defaultModelInterfaces'
import utilPermission, { PermissionType } from '@libs/utils/utilPermission'

export type roleTypeProps = 'ADMIN' | 'CUSTOMER'
export type roleInfoProps = {
  key: string
  type: USER_MODE_TYPE
  name: string
  messageId: string
}

export type userRoleProps = {
  currentRole: roleInfoProps
  admin: roleInfoProps
  sellers: roleInfoProps[]
  permissions?: PermissionType[]
}

const makeUserRoleFromWhoami = async (whoamiData: IModelFakeWhoami): Promise<userRoleProps> => {
  const userData = whoamiData.user.data
  const userRole = {
    admin: userData.isStaff
      ? {
          key: userData.id,
          type: USER_MODE.ADMIN,
          name: '관리자',
          messageId: 'ADMIN',
        }
      : null,
    sellers: userData.isSeller
      ? userData.sellers
          .filter((seller: IModelSellerDefault) => {
            return seller.status.value === 'APPROVED'
          })
          .map((seller: IModelSellerDefault) => {
            const { id, shopName } = seller
            return { key: id, type: USER_MODE.SELLER, name: shopName!, messageId: id }
          })
      : [],
  }
  const currentRole =
    loadUserRole()?.currentRole ||
    (userData.userMode === USER_MODE.ADMIN && userRole.admin) ||
    (userData.userMode === USER_MODE.SELLER && userRole.sellers && userRole.sellers[0])

  const permissions = userData.isStaff ? await utilPermission.fetchPermission(loadToken()) : []

  return {
    ...userRole,
    currentRole,
    permissions,
  } as userRoleProps
}

const useUserRole = () => useSelector((state: RootState) => state.reduxUIReducers.userRole)

const useUserMode = () => useCurrentRoleInfo().type

const useCurrentRoleInfo = (): roleInfoProps => {
  const userRole = useUserRole()

  if (userRole && userRole.currentRole) {
    return userRole.currentRole
  }
  return { key: '', type: USER_MODE.CUSTOMER, name: '', messageId: '' }
}

const useCurrentUserData = () => {
  const userRole = useUserRole()
  const roleInfos = userRole ? [userRole.admin, ...userRole.sellers].filter((i) => i) : []
  const currentRoleInfo = useCurrentRoleInfo()
  return {
    roleInfos,
    currentRoleInfo,
  }
}

const useWhoamiData = () => {
  let whoamiData = useSelector((state: RootState) => state.reduxDataReducers.fakeWhoamiData)
  if (!whoamiData) {
    // @ts-ignore
    whoamiData = new ModelFakeWhoami()
  }
  return whoamiData
}

const loadWhoamiData = () => {
  return store.getState().reduxDataReducers.fakeWhoamiData
}

const loadToken = () => localUserToken.load()

const loadUserRole = () => {
  return store.getState().reduxUIReducers.userRole || localUserRole.load()
}

const cleanUserInfo = () => {
  localUserToken.remove()
  localUserRole.remove()
  localKeepSignin.remove()
  store.dispatch(doSetFakeWhoamiData({} as IModelFakeWhoami))
  store.dispatch(doSetUserRole(undefined))
}

const useSaveUserInfo = () => {
  const dispatch = useDispatch()

  const saveTokenNWhoami = async (_token: string, whoami: IModelFakeWhoami) => {
    localUserToken.save(_token)

    const userRole = {
      ...(await makeUserRoleFromWhoami(whoami)),
    } as userRoleProps
    localUserRole.save(userRole)
    dispatch(doSetUserRole(userRole))
    dispatch(doSetFakeWhoamiData(whoami))
  }

  const saveCurrentRole = (currentRole: roleInfoProps) => {
    const userRole = loadUserRole()
    const updatedUserRole = {
      ...userRole,
      currentRole,
    }
    localUserRole.save(updatedUserRole)
    dispatch(doSetUserRole(updatedUserRole))
  }

  const saveToken = (token: string) => localUserToken.save(token)

  return {
    saveTokenNWhoami,
    saveToken,
    saveCurrentRole,
  }
}

const useAuthenticatedRoute = () => {
  const _hasSignedIn = localUserToken.load() !== undefined

  const checkLogin = (shouldLogin: boolean): boolean => {
    return !shouldLogin || _hasSignedIn
  }

  return {
    checkLogin,
  }
}

const useMenuSider = () => {
  const [componentMenu, setComponentMenu] = useState(<></>)
  const currentRoleInfo = useCurrentRoleInfo()

  useEffect(() => {
    if (currentRoleInfo.type === USER_MODE.ADMIN) setComponentMenu(<MenuSiderAdmin />)
    if (currentRoleInfo.type === USER_MODE.SELLER) setComponentMenu(<MenuSiderSeller />)
  }, [currentRoleInfo.type])

  return componentMenu
}

const isSuspendSeller = (user: IModelUser) => {
  if (user.sellers.length === 0) {
    return false
  }
  user.sellers.forEach((seller) => {
    if (seller.status.value === 'APPROVED') {
      return false
    }
  })
  return true
}

const useUserInfo = {
  useSaveUserInfo,
  useUserMode,
  useUserRole,
  useCurrentRoleInfo,
  useWhoamiData,
  loadWhoamiData,
  loadToken,
  loadUserRole,
  cleanUserInfo,
  useCurrentUserData,
  useAuthenticatedRoute,
  useMenuSider,
  isSuspendSeller,
}

export default useUserInfo
