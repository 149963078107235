import { BackendKeyRouteMapping, ROUTE_KEYS, ROUTE_KEYS_TYPE } from '@constants/constRoutes'

export type PermissionType = { permission: string; access: boolean }

const utilPermission = {
  fetchPermission: async (token: string): Promise<PermissionType[]> => {
    const result = await fetch(`${process.env.ADMIN_API_V4_URL}/users/me/permission?version=V3`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'user-mode': 'USER_MODE_ADMIN',
      },
    })
    return (await result.json()) as PermissionType[]
  },

  getRouteKeyByMenuId: (menuId: string): ROUTE_KEYS_TYPE | undefined => {
    return Object.keys(ROUTE_KEYS).find((key) => ROUTE_KEYS[key as ROUTE_KEYS_TYPE] === menuId) as ROUTE_KEYS_TYPE
  },

  getRouteKeyByPath: (path: string): ROUTE_KEYS_TYPE | undefined => {
    return Object.keys(BackendKeyRouteMapping).find(
      (key) => BackendKeyRouteMapping[key as ROUTE_KEYS_TYPE] === path
    ) as ROUTE_KEYS_TYPE
  },

  isAccessible: (menuId: string, permissions: PermissionType[]): boolean => {
    const permission = permissions.find((item) => item.permission === utilPermission.getRouteKeyByMenuId(menuId))
    // backend 에서 관리되지 않는 경우에는 접근가능
    if (!permission) {
      return true
    }

    return permission.access
  },

  isAccessibleByPath: (path: string, permissions: PermissionType[]): boolean => {
    const permission = permissions.find((item) => item.permission === utilPermission.getRouteKeyByPath(path))
    // backend 에서 관리되지 않는 경우에는 접근가능
    if (!permission) {
      return true
    }

    return permission.access
  },
}
export default utilPermission
